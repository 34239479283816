export default {
  okText: 'ยืนยัน',
  closeText: 'ปิด',
  cancelText: 'ยกเลิก',
  loadingText: 'กำลังดาวน์โหลด...',
  saveText: 'บันทึก',
  delText: 'ลบ',
  resetText: 'รีเซ็ต',
  searchText: 'ค้นหา',
  queryText: 'สอบถามตอนนี้',
  nextText: 'ขั้นตอนต่อไป',

  inputText: 'กรุณาป้อน',
  chooseText: 'กรุณาเลือก',

  add: 'เพิ่ม',
  update: 'อัปเดต',
  refresh: 'รีเฟรช',
  back: 'กลับ',

  light: 'ธีมสว่าง',
  dark: 'ธีมมืด',
  countdown: {
    normalText: 'รับรหัสยืนยัน',
    sendText: 'รับอัปเดตใหม่ใน {0} วินาที',
  },
};
